<template>
  <div>
    <v-skeleton-loader v-if="!loading" type="article"></v-skeleton-loader>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row align="center">
          <v-col cols="12" lg="3" sm="6">
            <v-text-field v-model="name" label="Название шаблона" :rules="[rules.requiredName]" color="teal"></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" sm="6">
            <v-select
              v-model="group"
              :items="groups"
              itemText="name"
              itemValue="id"
              label="Группа шаблона"
              color="teal"
              item-color="teal lighten-1"
            ></v-select>
          </v-col>
        </v-row>
        <v-expansion-panels tile accordion class="mb-3">
          <v-expansion-panel v-for="(day, dayIndex) in days" :key="day.number" >
            <v-expansion-panel-header class="px-2 py-0">
              <template v-slot:actions class="order-1">
                <v-icon color="teal" >
                  mdi-menu-down
                </v-icon>
              </template>
              <div class="order-2 d-flex align-center justify-space-between w-100">
                <div class="ml-2">День {{ dayIndex + 1 }}</div>
                <v-btn v-if="days.length > 1" icon @click.stop="removeDay(dayIndex)"><v-icon>mdi-trash-can</v-icon></v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="(task, ind) in day.tasks" :key="day.number+'_'+ind" align="center" class="day-new-task">
                <v-col cols="12" lg="2" sm="5" class="order-1">
                  <v-select
                    v-model="task.training_id"
                    :items="trainings"
                    item-text="human_name"
                    :rules="[rules.requiredTraining]"
                    item-value="id"
                    label="Тренинг"
                    color="teal"
                    item-color="teal lighten-1"
                  ></v-select>
                </v-col>
                <v-col cols="12" lg="2" sm="5" class="order-2">
                  <v-text-field label="Уровень. План" type="number" step="1" v-model.number="task.level_plan" color="teal"></v-text-field>
                </v-col>
                <v-col cols="12" sm="auto" class="flex-grow-1 order-3 order-sm-4 order-lg-3 py-0 ">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Тренниг. Успех. Сообщение" v-model.number="task.message_success" color="teal"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Тренниг. Неуспех. Сообщение" v-model.number="task.message_fail" color="teal"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="auto" class="order-4 order-sm-3 order-lg-4 ml-auto d-flex justify-end">
                  <v-btn icon v-if="day.tasks.length > 1" @click="removeTask(dayIndex,ind)"><v-icon>mdi-trash-can</v-icon></v-btn>
                </v-col>
              </v-row>
              
              <v-btn  @click="addTask(dayIndex)" dark color="teal lighten-2" class="mb-3 mt-4">
                <v-icon left>mdi-plus-thick</v-icon>
                Добавить тренинг
              </v-btn>
              <v-row align="center">
                <v-col cols="12" sm="6">
                  <v-text-field v-model="day.message_success" label="День. Успех. Сообщение" color="teal"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="day.message_fail" label="День. Неуспех. Сообщение" color="teal"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <v-btn @click="addDay" dark color="teal lighten-2">
        <v-icon left>mdi-plus-thick</v-icon>
        Добавить день
      </v-btn>
      <v-divider class="my-5"></v-divider>
      <div class="d-flex flex-wrap">
        <v-btn @click="save" dark color="teal" class="mr-3 my-2">
          Сохранить
        </v-btn>
        <v-btn @click="remove" v-if="templateId" dark color="red darken-2" class="mr-3 my-2">
          В архив
        </v-btn>
        <v-btn @click="$router.go(-1)" dark color="blue-grey lighten-2" class="mr-3 my-2">
          Отмена
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
 
export default {
  data: () => ({
    valid: true,
    loadingGroups: true,
    loadingTemplate: true,
    name: '',
    templateId: null,
    group: null,
    groups: [],
    days: [],
    rules: {
      requiredTraining: value => !!value || 'Выберите тренинг.',
      requiredName: value => !!value || 'Введите название шаблона.',
    },
  }),
  created() {
    this.getGroups();
    if (this.$route.params.id) {
      this.templateId = +this.$route.params.id;
      this.getTemplate()
    } else {
      this.loadingTemplate = false;
      this.addDay();
    }
  },
  computed: {
    loading() {
      return !this.loadingGroups && !this.loadingTemplate;
    },
    trainings() {
      return this.$store.state.trainings;
    }
  },
  methods: {
    addDay() {
      let count = this.days.length;
      let day = {
        tasks: [],
        message_success: '',
        message_fail: ''
      }
      this.$set(this.days, count, day);
      this.addTask(count);
    },
    removeDay(dayIndex) {
      this.days.splice(dayIndex,1)
    },
    addTask(dayIndex) {
      let training = this.trainings[0];
      let training_id = null;
      if (training) {
        training_id = training.id;
      }
      let task = { training_id, level_plan: '', message_success:'',message_fail:''}
      this.$set(this.days[dayIndex].tasks, this.days[dayIndex].tasks.length, task);
      
    },
    removeTask(dayIndex,ind) {
      this.days[dayIndex].tasks.splice(ind,1)
    },
    getTemplate() {
      let url = `/templates/${this.templateId}`;
       this.$api.get(url)
        .then(resp => {
          let template = resp.data._embedded.template;
          this.name = template.name;
          this.group = template.group.id;
          this.days = template.days || [];
          this.loadingTemplate = false;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getGroups() {
      let url = '/templates-groups';
       this.$api.get(url)
        .then(resp => {
          this.groups = resp.data._embedded.groups;
          this.loadingGroups = false;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.templateId) {
          this.patch()
        } else {
          this.store()
        }
      }
    },
    store() {
      if (this.$refs.form.validate()) {
        let url = '/templates'
         this.$api.post(url,{id: this.templateId, days:this.days, group_id: this.group, name: this.name})
          .then(resp => {
            console.log(resp); 
            let id = resp.data._embedded.id;
            this.$store.dispatch('addMessage', 'Шаблон сохранен')
            this.$router.push({ path: `/templates/${id}/edit` })
          })
          .catch(error => {     
            console.log(error);      
              this.$store.dispatch('addRequestError', error)
          })
      }
    },
    patch() {
      if (this.$refs.form.validate()) {
        let url = `/templates/${this.templateId}`
         this.$api.patch(url,{days:this.days, group_id: this.group, name: this.name})
          .then(() => {
            this.$store.dispatch('addMessage', 'Изменения сохранены')
          })
          .catch(error => {           
              this.$store.dispatch('addRequestError', error)
          })
      }
    },
    remove() {
      let url = `/templates/${this.templateId}`
       this.$api.delete(url)
        .then(() => {
          //let id = resp._embedded.id;
          this.$router.push({ path: `/syllabi` })
          this.$store.dispatch('addMessage', 'Шаблон отправлен в архив')
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    }
  }
}
</script>
