<template>
<div class="trainings">
    <page-title :title="'Редактировать шаблон'" :items="items"></page-title>
    <v-card class="pa-3 rounded-0 flat custom__card">
      <template-form></template-form>
    </v-card>
  </div>
</template>

<script>
import PageTitle from '../../components/main/PageTitle'
import TemplateForm from '../../components/template/TemplateForm'

export default {
  components: { 
    PageTitle,
    TemplateForm,
  },
  computed: {
    items() {
      return [
          {
            text: 'Главная',
            disabled: false,
            to: '/',
          },
          {
            text: 'Программы',
            disabled: false,
            link: true,
            href: '/syllabi',
          },
          {
            text: 'Редактировать шаблон',
            disabled: true,
          },
        ]
    },
  },
}
</script>

<style>

</style>